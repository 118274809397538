import React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

export default function about() {
  return (
    <Layout>
      <div className="container">
        <StaticImage
          src="../images/logo.svg"
          alt="Dream Mill"
          className="about-logo"
        />
        <div className="row align-items-start">
          <div className="col-sm">
            <p>
              2017 - Dream Mill is founded - a modern agency which unites
              diverse competences to help brands communicate effectively
            </p>
            <p>
              We help clients in their decision making by giving insights and
              perspectives on a full spectrum of [fill in the right word!],
              including advertising, design, web and seo
            </p>
            <p>
              Available from Dream Mill and partners: Consumer insight, design,
              SEO, SoMe strategy, campaign design and production. Keep things
              together.
            </p>
            <p>Keep it unify</p>
          </div>
          <div className="col-sm">
            <StaticImage
              src="../images/logo.svg"
              alt="Dream Mill"
              className="about-img"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
